import datasets from './datasets.js';
import clicksEvents from './actions/clicksEvents.js';
import fetch from 'node-fetch';

window.groupeNordik.serverSideTracking = {
  init: function (el) {
    clicksEvents.clickToOtherHostsInit();
  },

  // apos.util.getCookie() was not returning the data as I wanted,
  // so I created my own function
  getCookie: (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length >= 2) {
      return decodeURIComponent(parts[1].split(';')[0]);
    }
  },

  // Send the events to the sGTM server
  sendMetrics: async function (data) {
    // Reference for the data structure for GA4:
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/config

    // Add custom data to the payload that are generic fields for all events.
    // Set semi-random session_id and client_id. Those are standard GA4 fields.
    data.session_id = this.getCookie('metrics_id').split('.')[0];
    data.client_id = this.getCookie('metrics_id');
    // Add the page location and title
    data.page_location = window.location.href;
    data.page_title = document.title;
    // Add the language and user agent
    data.language = navigator.language.toLowerCase();
    data.user_agent = navigator.userAgent;
    // Add the referrer if it's not the same as the current page
    if (document.referrer !== '' && document.referrer !== window.location.href) {
      data.page_referrer = document.referrer;
    }

    // Add UTM parameter tracking
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {
      campaign_source: urlParams.get('utm_source'),
      campaign_medium: urlParams.get('utm_medium'),
      campaign_name: urlParams.get('utm_campaign'),
      campaign_term: urlParams.get('utm_term'),
      campaign_content: urlParams.get('utm_content'),
      // Add gclid for Google Ads tracking
      gclid: urlParams.get('gclid'),
      // Add fbclid for Facebook tracking
      fbclid: urlParams.get('fbclid'),
    };

    // Only add UTM params that are present in the URL
    Object.entries(utmParams).forEach(([key, value]) => {
      if (value) {
        data[key] = value;
      }
    });

    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };

    // Add the debug mode header if the debug cookie is set
    // The cookie can be set by adding ?metrics_debug=XXXXX to the URL.
    // This can be retrieved in the debug mode in sGTM.
    if (this.getCookie('metrics_debug')) {
      // Debug mode for GA4
      data.debug_mode = true;
      data.engagement_time_msec = 1400;
      // Also add the sGTM debug header to the request based on the cookie
      headers['X-Gtm-Server-Preview'] = this.getCookie('metrics_debug');
    }

    try {
      // Validate the config exists to retrieve the GTM conf
      if (!window.groupeNordikConfig) {
        throw new Error('Undefined metrics server');
      }
      const { host, vanillaPath } = window.groupeNordikConfig.gtm;

      const response = await fetch(`${host}${vanillaPath}`, {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify([data]),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  // Simulate a standard checkout process with delays
  tests_standard: function (datasetName) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(datasets[datasetName]);
  },

  // Simulate a vanilla checkout process with delays
  tests_vanilla: async function (datasetName) {
    await this.sendMetrics(datasets[datasetName]);
  },
  ...clicksEvents,
};

window.groupeNordik._onLoad.serverSideTracking = () => {
  window.groupeNordik.serverSideTracking.init();
};

export default window.groupeNordik.serverSideTracking;

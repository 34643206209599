const { playStories } = require('../modules/@groupenordik/story-card-widget/views/scripts');
const moment = require('moment');
require('core-js/stable');
require('regenerator-runtime/runtime');

const client = new XMLHttpRequest();
const lastModified = new Date();
const url = window.location.href;

lastModified.setDate(lastModified.getDate() - 1);

client.open('GET', url, true);
client.setRequestHeader('If-Modified-Since', lastModified.toUTCString());
client.setRequestHeader('Last-Modified', lastModified.toUTCString());
client.send();

window.lunrFAQ = [];

window.recaptchaOnLoad = () => {
  const recaptcha = document.getElementById('g-recaptcha-response-100000');
  if (recaptcha) {
    recaptcha.setAttribute('aria-label', 'Google Recaptcha');
  }
};

export const Recaptcha = {
  loadScript: () => {
    if (!document.head.querySelector('[data-recaptcha]')) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://www.google.com/recaptcha/api.js?render=${__RECAPTCHA__}&onload=recaptchaOnLoad`;
      script.dataset.recaptcha = true;
      document.head.appendChild(script);
    }
  },

  execute: (callback) => {
    if (typeof window.grecaptcha !== 'undefined') {
      window.grecaptcha.ready(() =>
        window.grecaptcha
          .execute(__RECAPTCHA__, {
            action: 'submit',
          })
          .then(callback),
      );
    }
  },
};

export const throttle = (callback, wait = 500, immediate = false) => {
  let timer = null;
  let initialCall = true;

  return (...args) => {
    const callNow = immediate && initialCall;
    const next = () => {
      callback.apply(this, args);
      timer = null;
    };

    if (callNow) {
      initialCall = false;
      next();
    }

    if (!timer) {
      timer = setTimeout(next, wait);
    }
  };
};

export const debounce = (callback, wait = 500) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(this, args);
    }, wait);
  };
};

export const isElementInViewport = (el) => {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const getReferralId = () => {
  const cookie = document.cookie.split('; ').find((row) => row.startsWith('cje='));

  if (!cookie) {
    return null;
  }

  return cookie.split('=')[1];
};

window.importPost = async () => {
  const response = await apos.http.post('/api/v1/post-importer/import', {});
  return response;
};

window.groupeNordik = {

  // All functions defined in "onLoad" will be executed modules/asset/ui/src/index.js on page load
  // This object is also populated by other scripts
  _onLoad: {
    markBodyAsLoggedIn: () => {
      if (document.querySelector('body .apos-admin-bar-wrapper')) {
        document.querySelector('body').classList.add('apos-is-logged-in');
      }
    },

    dynamicNav: () => {
      const header = document.querySelector('.header');

      if (header) {

        //newsletter and christmas popup moved here
        //because the double onload was not displaying popup on center villages
        const date = new Date();

        const startDateString = document.querySelector('#christmas-modal').getAttribute('data-start-date');
        const endDateString = document.querySelector('#christmas-modal').getAttribute('data-end-date');

        const startTestDateString = document.querySelector('#christmas-modal').getAttribute('data-test-start-date');
        const endTestDateString = document.querySelector('#christmas-modal').getAttribute('data-test-end-date');

        const start = new Date(startDateString);
        const end = new Date(endDateString);

        const startTest = new Date(startTestDateString);
        const endTest = new Date(endTestDateString);

        const christmasModal = document.getElementById('christmas-modal');
        const christmasModalClose = document.getElementById('christmas-modal-close');

        christmasModalClose.addEventListener('click', () => {
          christmasModal.style.display = 'none';
        });

        function christmasModalShow() {
          christmasModal.style.display = 'flex';
        }

        if ((date >= start && date <= end) || (date >= startTest && date <= endTest)) {
          if (date >= startTest && date <= endTest) {
            sessionStorage.removeItem("hasCodeRunBefore");
          }

          if (!sessionStorage.getItem("hasCodeRunBefore")) {
            setTimeout(christmasModalShow, 300);
            sessionStorage.setItem("hasCodeRunBefore", true);
          }
        }

        const logo = header.querySelector('.header__logo--arrow');
        const villages = header.querySelector('.villages');
        const shadow = header.querySelector('.header__shadow');
        const nav = header.querySelector('.navigation');
        const burger = header.querySelector('.header__burger');
        const quickBooking = header.querySelector('.booking');
        const openBookingBtn = header.querySelector('.open_booking_btn');
        const quickGiving = header.querySelector('.giving');
        const openGivingBtn = header.querySelector('.open_giving_btn');
        const balance = header.querySelector('.balance');
        const openBalanceBtn = header.querySelector('.open_balance_btn');

        const iconBooking = openBookingBtn.querySelector('i');

        let iconGiving;
        if (quickGiving) {
          iconGiving = openGivingBtn.querySelector('i');
        }

        let iconBalance;
        if (balance) {
          iconBalance = openBalanceBtn.querySelector('i');
        }

        const toggleShadow = (enable = true) => {
          if (shadow) {
            if (enable) {
              shadow.classList.add('header__shadow--active');
            } else {
              if (!quickBooking || !quickBooking.classList.contains('booking--opened')) {
                shadow.classList.remove('header__shadow--active');
              }
            }
          }
        };

        const updateNavMove = () => {
          if (window.scrollY >= 72) {
            header.classList.add('header--scrolled');
          } else {
            header.classList.remove('header--scrolled');
          }
        };

        // Scrolling
        window.addEventListener('scroll', updateNavMove);
        updateNavMove();

        // Shadow
        if (shadow && nav) {
          nav.querySelectorAll('.navigation__dropdown_container').forEach((iNavItem) => {
            iNavItem.addEventListener('mouseover', () => toggleShadow(true));
            iNavItem.parentElement.addEventListener('mouseover', () => toggleShadow(true));

            iNavItem.addEventListener('mouseout', () => toggleShadow(false));
            iNavItem.parentElement.addEventListener('mouseout', () => toggleShadow(false));
          });
        }

        // Logo & Villages menu
        if (logo && villages && burger) {
          const burgerIcon = burger.querySelector('i');

          logo.addEventListener('click', () => {
            if (villages.classList.contains('villages--opened')) {
              // Menu is opened, close it
              header.classList.remove('header--opened', 'header--villages_opened');
              villages.classList.remove('villages--opened');
              burgerIcon.classList.remove('icon__close');
              burgerIcon.classList.add('icon__burger');
              document.body.classList.remove('hide-scrollbar');
            } else {
              // Menu is closed, open it
              header.classList.add('header--opened', 'header--villages_opened');
              villages.classList.add('villages--opened');
              burgerIcon.classList.add('icon__close');
              burgerIcon.classList.remove('icon__burger');
              document.body.classList.add('hide-scrollbar');

              if (nav) {
                header.classList.remove('header--navigation_opened');
                nav.classList.remove('navigation--opened');
              }
            }
          });
        }

        // Burger button
        if (burger && nav) {
          const burgerIcon = burger.querySelector('i');

          burger.addEventListener('click', () => {
            if (header.classList.contains('header--opened')) {
              // Menu is opened, close it
              header.classList.remove('header--opened', 'header--navigation_opened');
              nav.classList.remove('navigation--opened');
              burgerIcon.classList.remove('icon__close');
              burgerIcon.classList.add('icon__burger');
            } else {
              // Menu is closed, open it
              header.classList.add('header--opened', 'header--navigation_opened');
              nav.classList.add('navigation--opened');
              burgerIcon.classList.add('icon__close');
              burgerIcon.classList.remove('icon__burger');
            }

            if (villages) {
              header.classList.remove('header--villages_opened');
              villages.classList.remove('villages--opened');
            }
          });
        }

        // Mobile dropdown menu toggles
        if (nav) {
          const toggleButtons = nav.querySelectorAll('.navigation__toggle_dropdown');
          toggleButtons.forEach(button => {
            button.addEventListener('click', () => {
              const parentItem = button.closest('.navigation__item');
              const dropdownContainer = parentItem.querySelector('.navigation__dropdown_container');
              const arrowIcon = button.querySelector('.icon__arrow-down');

              if (dropdownContainer.style.display === 'block') {
                dropdownContainer.style.display = 'none';
                // Remove rotation when dropdown is closed
                arrowIcon.classList.remove('icon__arrow-down--rotated');
              } else {
                dropdownContainer.style.display = 'block';
                // Add rotation when dropdown is opened
                arrowIcon.classList.add('icon__arrow-down--rotated');
              }
            });
          });
        }

        // Online Reservation Quick Booking
        if (quickBooking && openBookingBtn) {
          const toggleQuickBooking = (state = null) => {
            const isOpened = quickBooking.classList.contains('booking--opened');
            iconBooking.classList.remove('icon__calendar', 'icon__close');

            if (state === false || isOpened) {
              quickBooking.classList.remove('booking--opened');
              openBookingBtn.classList.remove('header__cta_btn--active');
              iconBooking.classList.add('icon__calendar');

              toggleShadow(false);
            } else if (state === true || !isOpened) {
              quickBooking.classList.add('booking--opened');
              openBookingBtn.classList.add('header__cta_btn--active');
              iconBooking.classList.add('icon__close');

              if (quickGiving && quickGiving.classList.contains('giving--opened')) {
                quickGiving.classList.remove('giving--opened');
                openGivingBtn.classList.remove('header__cta_btn--active');
                iconGiving.classList.remove('icon--gift', 'icon__close');
                iconGiving.classList.add('icon--gift');
              }

              if (balance && balance.classList.contains('balance--opened')) {
                balance.classList.remove('balance--opened');
                openBalanceBtn.classList.remove('header__cta_btn--active');
                iconBalance.classList.remove('icon--gift', 'icon__close');
                iconBalance.classList.add('icon--gift');
              }

              toggleShadow(true);
            }
          };

          openBookingBtn.addEventListener('click', toggleQuickBooking);

          // Close Quick Booking if we click on the backdrop
          if (shadow) {
            shadow.addEventListener('click', () => {
              toggleQuickBooking(false);
            });
          }

          // Quick Booking form
          const centerField = document.getElementById('booking__center');
          const dateField = document.getElementById('booking__date');
          const submitBtns = document.querySelectorAll('.booking__submit-btn');
          const selectArrow = document.querySelector('.select-arrow');

          if (centerField && dateField && submitBtns.length) {
            // Handle arrow rotation for the date input field
            dateField.addEventListener('click', function() {
              this.classList.toggle('active');
            });

            dateField.addEventListener('blur', function() {
              this.classList.remove('active');
            });

            // Add change event listener to reset arrow when date is selected
            dateField.addEventListener('change', function() {
              this.classList.remove('active');
            });

            // Handle arrow rotation for the select field
            // We target the adjacent span.select-arrow
            centerField.addEventListener('click', function() {
              if (selectArrow) {
                selectArrow.classList.add('active');
              }
            });

            centerField.addEventListener('blur', function() {
              if (selectArrow) {
                selectArrow.classList.remove('active');
              }
            });

            centerField.addEventListener('change', function() {
              if (selectArrow) {
                selectArrow.classList.remove('active');
              }
            });

            const updateForm = () => {
              // Update calendar
              dateField.setAttribute('min', centerField.value === '4' ? '2022-10-06' : window.groupeNordik.$formatDate(new Date()));

              // Update submit button
              submitBtns.forEach((iSubmitBtn) => {
                iSubmitBtn.style.display = iSubmitBtn.dataset.centerid === centerField.value ? 'block' : 'none';
              });
            };
            updateForm();
            centerField.addEventListener('change', updateForm);

            submitBtns.forEach((iSubmitBtn) => {
              iSubmitBtn.addEventListener('click', (element) => {
                element.preventDefault();

                if (centerField.value) {
                  const date = dateField.value ? moment(dateField.value).format('YYYYMMDD') : null;
                  let url = element.target.href;

                  if (date) {
                    url += `${url.includes('?') ? '&' : '?'}date=${date}`;
                  }
                  // Send the event to the server-side tracking before redirecting the user
                  window.groupeNordik.serverSideTracking.clickToOtherHosts(url)
                    .then(r => {
                      window.open(url, '_self').focus();
                    });
                }
              });
            });

            /* submitBtn.addEventListener('click', () => {
              if (centerField.value) {
                const date = dateField.value ? moment(dateField.value).format('YYYYMMDD') : null;
                const url = `${centerField.selectedOptions[0].dataset.url}${date ? '?date=' + date : ''}`;
                window.open(url, '_self').focus();
              }
            }); */
          }
        }
        // Gift Certificate Home Page
        if (quickGiving && openBookingBtn) {
          const toggleQuickGiving = (state = null) => {
            const isOpened = quickGiving.classList.contains('giving--opened');

            iconGiving.classList.remove('icon--gift', 'icon__close');

            if (state === false || isOpened) {
              quickGiving.classList.remove('giving--opened');
              openGivingBtn.classList.remove('header__cta_btn--active');
              iconGiving.classList.add('icon--gift');

              toggleShadow(false);
            } else if (state === true || !isOpened) {
              quickGiving.classList.add('giving--opened');
              openGivingBtn.classList.add('header__cta_btn--active');
              iconGiving.classList.add('icon__close');

              if (quickBooking.classList.contains('booking--opened')) {
                quickBooking.classList.remove('booking--opened');
                openBookingBtn.classList.remove('header__cta_btn--active');
                iconBooking.classList.remove('icon__calendar', 'icon__close');
                iconBooking.classList.add('icon__calendar');
              }

              if (balance && balance.classList.contains('balance--opened')) {
                balance.classList.remove('balance--opened');
                openBalanceBtn.classList.remove('header__cta_btn--active');
                iconBalance.classList.remove('icon--gift', 'icon__close');
                iconBalance.classList.add('icon--gift');
              }

              toggleShadow(true);
            }
          };

          openGivingBtn.addEventListener('click', toggleQuickGiving);

          // Close Gift Certificate if we click on the backdrop
          if (shadow) {
            shadow.addEventListener('click', () => toggleQuickGiving(false));
          }

          // Gift Certificate form
          const centerField = document.getElementById('giving__center');
          const submitBtn = document.getElementById('giving__submit');

          if (centerField && submitBtn) {
            submitBtn.addEventListener('click', (e) => {
              if (centerField.value) {
                const url = new URL(centerField.selectedOptions[0].dataset.url);

                const referralId = getReferralId();
                if (referralId) {
                  url.searchParams.append('cjevent', referralId);
                }

                e.preventDefault();
                // Send the event to the server-side tracking before redirecting the user
                window.groupeNordik.serverSideTracking.clickToOtherHosts(url.href)
                  .then(r => {
                    window.open(url.href, '_self').focus();
                  });
              }
            });
          }
        }

        if (balance && openBookingBtn) {
          const toggleBalance = (state = null) => {
            const isOpened = balance.classList.contains('balance--opened');

            iconBalance.classList.remove('icon--gift', 'icon__close');

            if (state === false || isOpened) {
              balance.classList.remove('balance--opened');
              openBalanceBtn.classList.remove('header__cta_btn--active');
              iconBalance.classList.add('icon--gift');

              toggleShadow(false);
            } else if (state === true || !isOpened) {
              balance.classList.add('balance--opened');
              openBalanceBtn.classList.add('header__cta_btn--active');
              iconBalance.classList.add('icon__close');

              if (quickBooking.classList.contains('booking--opened')) {
                quickBooking.classList.remove('booking--opened');
                openBookingBtn.classList.remove('header__cta_btn--active');
                iconBooking.classList.remove('icon__calendar', 'icon__close');
                iconBooking.classList.add('icon__calendar');
              }

              if (quickGiving && quickGiving.classList.contains('giving--opened')) {
                quickGiving.classList.remove('giving--opened');
                openGivingBtn.classList.remove('header__cta_btn--active');
                iconGiving.classList.remove('icon--gift', 'icon__close');
                iconGiving.classList.add('icon--gift');
              }

              toggleShadow(true);
            }
          };

          openBalanceBtn.addEventListener('click', toggleBalance);

          // Close Gift Certificate if we click on the backdrop
          if (shadow) {
            shadow.addEventListener('click', () => toggleBalance(false));
          }

          // Balance form
          const centerField = document.getElementById('balance__center');
          const submitBtn = document.getElementById('balance__submit');

          if (centerField && submitBtn) {
            submitBtn.addEventListener('click', () => {
              if (centerField.value) {
                const url = `${centerField.selectedOptions[0].dataset.url}`;
                window.open(url, '_self').focus();
              }
            });
          }
        }
      }
    },

    handleReferralId: () => {
      const currentURL = new URL(window.location.href);
      const referralId = currentURL.searchParams.get('cjevent') || getReferralId();

      if (referralId) {
        // Add ReferralId to OR and ACC links
        const links = document.querySelectorAll(
          'a[href*="https://tribu.groupenordik.com"], a[href*="https://app.lenordik.com"], a[href*="https://app.thermea.ca"], a[href*="https://app.thermea.com"]',
        );
        links.forEach((iLink) => {
          const url = new URL(iLink.href);
          url.searchParams.append('cjevent', referralId);
          iLink.href = url.href;
        });
      }
    },
  },

  $disableElements: (elements, state) => {
    elements.forEach((iElement) => (iElement.disabled = state));
  },

  $playStories: (event, id) => {
    playStories(event, id);
  },

  $formatDate: (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }

    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  },
};

import { tns } from 'tiny-slider/src/tiny-slider';

window.groupeNordik.tab = {
  openTabs: (tabCategory) => {
    document.querySelectorAll('.w-tab__tablinks').forEach(element => element.classList.remove('borderBottom'));
    document.getElementById(`${tabCategory}__tablinks`).classList.add('borderBottom');
  },
  init: (el) => {
    const firstTabLink = el.querySelector('.w-tab__tablinks');
    if (firstTabLink) {
      firstTabLink.classList.add('borderBottom');
    }

    const nestedSliders = [];
    el.querySelectorAll('.w-tab__nested').forEach(element => {
      nestedSliders.push(tns({
        container: element,
        items: 1,
        startIndex: 0,
        controls: false,
        mouseDrag: false,
        touch: false,
        nav: false,
        loop: false,
        navPosition: 'bottom',
        slideBy: 1,
        disable: true,
        nested: 'inner',
        responsive: {
          620: {
            nested: 'inner',
            mouseDrag: false,
            touch: false,
          },
        },
      }));
    });

    const sliderController = el.querySelector('.w-tab__slider_controller');

    const slider = tns({
      container: el.querySelector('.w-tab__slider'),
      navContainer: sliderController || false,
      nav: !!sliderController,
      nested: 'outer',
      items: 1,
      controls: false,
      mouseDrag: true,
      touch: true,
      autoplay: false,
      loop: false,
      disable: false,
      responsive: {
        620: {
          disable: false,
          mouseDrag: true,
          touch: true,
        },
      },
    });

    slider.events.on('newBreakpointStart', (info, eventName) => {
      nestedSliders.forEach(element => {
        element.goTo(0);
      });
    });

    if (sliderController) {
      slider.events.on('indexChanged', (info, eventName) => {
        const { navItems } = info;
        const currentNavItem = Array.from(navItems).find(iNavItem => iNavItem.classList.contains('tns-nav-active')).dataset.service;
        const currentNavItemElement = el.querySelector(`#${currentNavItem}__tablinks`);
        const tabHeader = el.querySelector('.w-tab__header');

        if (currentNavItemElement && tabHeader) {
          const containerWidth = tabHeader.offsetWidth;
          const navItemLeftOffset = currentNavItemElement.offsetLeft;
          const idealScrollLeft = navItemLeftOffset - (containerWidth - currentNavItemElement.offsetWidth) / 2;

          tabHeader.scrollTo({
            left: idealScrollLeft,
            behavior: 'smooth',
          });
          el.querySelectorAll('.w-tab__tablinks').forEach(element => element.classList.remove('borderBottom'));
          el.querySelector(`#${currentNavItem}__tablinks`).classList.add('borderBottom');
        }
      });
    }
  },
};

import { tns } from 'tiny-slider/src/tiny-slider';
import { storyExecution } from '../../story-card-widget/views/scripts.js';

window.groupeNordik.videoSliderHover = {
  init: el => {
    const sliderContainer = el.querySelector('.w-video-hover-slider__slider');
    const slideCount = sliderContainer.children.length;

    const slider = tns({
      container: sliderContainer,
      controlsContainer: el.querySelector('.w-videoCarousel__selector'),
      swipeAngle: false,
      speed: 400,
      items: 1,
      gutter: 0,
      edgePadding: 48,
      controls: false,
      loop: false,
      autoplay: false,
      nav: slideCount > 1,
      navPosition: 'bottom',
      mouseDrag: true,
      responsive: {
        768: {
          items: 2,
          loop: false,
          nav: slideCount > 2,
          controls: false,
          navPosition: 'bottom',
          edgePadding: 48,
          gutter: 0,
        },
        992: {
          items: 4,
          nav: slideCount > 4,
          controls: false,
          edgePadding: 0,
          gutter: 0,
        },
      },
    });

    //add the support to play video on hover image
    const containers = document.querySelectorAll(".w-video-slider__card-container.has-video");

    containers.forEach(container => {
      const video = container.querySelector(".w-video-slider__video");

      container.addEventListener("mouseover", () => video.play());

      container.addEventListener("mouseout", () => {
        video.pause();
        //video.currentTime = 0; // Reset video to start
      });
    });
  },
};

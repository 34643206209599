window.groupeNordik.popupIframe = {
  init: (el) => {
    const popup = el.querySelector(`popup-custom`);
    const popupContainer = el.querySelector(`.w-banner__popup-container`);
    const closeButton = el.querySelector('.w-banner__popup-close-action');

    if (popup) {
      // JavaScript for handling the opening and closing of the popup
      el.querySelector('.btn--play').addEventListener('click', function () {
        // Create the iframe element
        const iframe = document.createElement('iframe');

        // Set the properties
        iframe.src = popup.dataset.iframe_url;
        iframe.frameBorder = '0';
        iframe.allow = 'autoplay; encrypted-media';
        iframe.allowFullscreen = true;

        popupContainer.appendChild(iframe);
        popup.style.display = 'flex';
      });

      closeButton.addEventListener('click', function () {
        popup.style.display = 'none';

        const iframe = popupContainer.querySelector('iframe');
        if (iframe) {
          iframe.remove();
        }
      });

      // Close popup with esc
      el.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
          const openPopup = el.querySelector('popup-custom[style*="display: flex"]');
          if (openPopup) {
            openPopup.style.display = 'none';

            const iframe = popupContainer.querySelector('iframe');
            if (iframe) {
              iframe.remove();
            }
          }
        }
      });
    }
  },
};

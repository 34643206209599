const moment = require('moment');

window.groupeNordik.reservationStarter = (el) => {
  const ID = el.dataset.id;
  const centerField = document.getElementById(`${ID}__center`);
  const dateField = document.getElementById(`${ID}__date`);
  const submitBtns = document.querySelectorAll('.w-reservation-starter__submit-btn');

  const updateForm = () => {
    // fix for Chrome bug on history back action for select value
    if (window.groupeNordikConfig.brand === 'nordik') {
      if (centerField.value) {
        localStorage.setItem('hasCodeRunBefore', centerField.value);
      } else {
        centerField.value = localStorage.getItem('hasCodeRunBefore') || window.groupeNordikConfig.defaultCenterId;
      }
    }

    // Update calendar
    dateField.setAttribute('min', centerField.value === '4' ? '2022-10-06' : window.groupeNordik.$formatDate(new Date()));

    // Update submit button
    submitBtns.forEach(iSubmitBtn => {
      iSubmitBtn.style.display = iSubmitBtn.dataset.centerid === centerField.value ? 'block' : 'none';
    });
  };
  updateForm();

  centerField.addEventListener('change', updateForm);

  submitBtns.forEach(iSubmitBtn => {
    iSubmitBtn.addEventListener('click', (element) => {
      element.preventDefault();
      if (centerField.value) {
        const date = dateField.value ? moment(dateField.value).format('YYYYMMDD') : null;
        let url = element.target.href;

        if (date) {
          url += `${url.includes('?') ? '&' : '?'}date=${date}`;
        }

        window.open(url, '_self').focus();
      }
    });
  });
};
